import './App.css';
import {useRef} from 'react'

function App() {
  const scrollRef0=useRef(null)
  const ScollToRef0=()=>{scrollRef0.current.scrollIntoView({ behavior: 'smooth'})}
  const scrollRef1=useRef(null)
  const ScollToRef1=()=>{scrollRef1.current.scrollIntoView({ behavior: 'smooth'})}
  const scrollRef2=useRef(null)
  const ScollToRef2=()=>{scrollRef2.current.scrollIntoView({ behavior: 'smooth'})}
  const scrollRef3=useRef(null)
  const ScollToRef3=()=>{scrollRef3.current.scrollIntoView({ behavior: 'smooth'})}
  
  return (
    <div className="w-[100vw] h-[100vh] flex flex-col bg-[#D9F6FF]" style={{overflowY:'hidden'}}>
      {/*Nav Bar */}
      <div className='w-full pl-[5vw] bg-[transparent] top-0 fixed'>
        <div className='w-full h-[5vw] bg-[white] flex flex-row items-center pl-[4vw]' style={{borderTopLeftRadius:'999px',borderBottomLeftRadius:'999px'}}>
          <img onClick={ScollToRef0} className='w-[7vw] cursor-pointer' src='/logo.png' alt='content'/>
          <div className='flex-1 flex flex-row items-center justify-end pr-[5vw] select-none gap-[2vw] text-[1vw]'>
            <div onClick={ScollToRef1} className='cursor-pointer hover:font-bold'>功能</div>
            <div onClick={ScollToRef2} className='cursor-pointer hover:font-bold'>特點</div>
            <div onClick={ScollToRef3} className='cursor-pointer hover:font-bold'>聯繫</div>
          </div>
        </div>
      </div>

      {/* content */}
      <div className='w-full flex flex-col' style={{overflowY:'auto'}}>
        <div ref={scrollRef0}/>
        <img className='w-full' src='/s1.jpg' alt='content'/>
        <div className='relative'>
          <div className='absolute top-[17vw]' ref={scrollRef1}/>
        </div>
        <img className='w-full' src='/s2.jpg' alt='content'/>
        <div className='relative'>
          <div className='absolute top-[-5vw]' ref={scrollRef2}/>
        </div>
        <img className='w-full' src='/s3.jpg' alt='content'/>
        <div ref={scrollRef3}></div>

        {/*contact*/}
        <div className='w-full flex flex-row bg-[#00E1FF] items-center pl-[5vw] pr-[5vw]' style={{minHeight:'14vw'}}>
          <div className='flex flex-col'>
            <div className=' bg-[#154DAF] w-[1vw] h-[1vw] rounded-[99px]'/>
            <div className='text-[#154DAF] text-[2vw]' style={{fontWeight:'900'}}>聯繫我們</div>
          </div>
          <div className='flex-1 flex flex-row justify-center items-center ml-[10vw]'>
            <div className='flex flex-col justify-center items-center flex-1 py-[.5vw]'>
              <div className='text-[black] text-[1.3vw]' style={{fontWeight:'500'}}>電話</div>
              <div className='text-[black] text-[1.1vw] cursor-pointer' style={{fontWeight:'300'}}
                onClick={()=>{window.location='whatsapp://send?phone=85253922633&text=Inquiry for AI Receptionist:'}}
              >
                ＋852 5392 2633
              </div>
            </div>
            <div className='flex flex-col justify-center items-center flex-1 py-[.5vw]' style={{borderLeft:'.2vw solid white'}}>
              <div className='text-[black] text-[1.3vw]' style={{fontWeight:'500'}}>電子郵件</div>
              <div
                className='text-[black] text-[1.1vw] underline cursor-pointer'
                style={{fontWeight:'300'}}
                onClick={() => window.location = 'mailto:info@aquila-tech.io'}
              >info@aquila-tech.io</div>
            </div>
          </div>
        </div>

        {/*footer*/}
        <div className='w-full flex flex-row items-center justify-between pl-[5vw] pr-[5vw]' style={{minHeight:'14vw'}}>
          {/*left*/}
          <div className='flex-1 flex flex-col'>
            <div className='w-[40vw] flex flex-row items-center justify-start select-none gap-[2vw] text-[1vw] pb-[2vw]' style={{borderBottom:'.08vw solid black'}}>
              <div onClick={ScollToRef1} className='cursor-pointer hover:font-bold'>功能</div>
              <div onClick={ScollToRef2} className='cursor-pointer hover:font-bold'>特點</div>
              <div onClick={ScollToRef3} className='cursor-pointer hover:font-bold'>聯繫</div>
            </div>
            <div className='text-[1vw] font-[200] pt-[2vw]'>
              版權所有 © 2024 Aquila Technology 有限公司。
              {/* &nbsp;&nbsp;&nbsp;私隱政策 */}
            </div>
          </div>
          {/*rigjt*/}
          <img className='w-[8vw]' src='/logo2.png' alt='content'/>
        </div>
      </div>


      {/*popup*/}
      <div className='fixed right-0 bottom-[10vh] w-[20vw] h-[15vw] flex flex-col items-center justify-center pl-[2vw]' style={{
        background: 'linear-gradient(79.26deg, rgba(23, 222, 255, 0.75) 31.61%, rgba(23, 207, 255, 0.75) 55.18%, rgba(24, 170, 255, 0.75) 100.16%, rgba(26, 109, 255, 0.75) 160.14%, rgba(28, 54, 255, 0.75) 211.56%)',
        borderTopLeftRadius:'999px',borderBottomLeftRadius:'999px',borderLeft:'.7vw solid white',borderTop:'.7vw solid white',borderBottom:'.7vw solid white'
      }}>
        <div className='flex flex-col'>
          <div className=' bg-[#154DAF] w-[1vw] h-[1vw] rounded-[99px]'/>
          <div className='text-[#154DAF] text-[2vw]' style={{fontWeight:'900'}}>聯繫我們</div>
        </div>

        <div className='flex flex-col justify-center items-center py-[.3vw]'>
          <div className='text-[black] text-[1.3vw]' style={{fontWeight:'500'}}>電話</div>
          <div className='text-[black] text-[1.1vw] cursor-pointer' style={{fontWeight:'300'}}
            onClick={()=>{window.location='whatsapp://send?phone=85253922633&text=Inquiry for AI Receptionist:'}}
          >
            ＋852 5392 2633
          </div>
        </div>

        <div className='flex flex-col justify-center items-center py-[.3vw]' style={{borderTop:'.1vw solid white'}}>
            <div className='text-[black] text-[1.3vw]' style={{fontWeight:'500'}}>電子郵件</div>
            <div
              className='text-[black] text-[1.1vw] underline cursor-pointer'
              style={{fontWeight:'300'}}
              onClick={() => window.location = 'mailto:info@aquila-tech.io'}
            >info@aquila-tech.io</div>
          </div>
      </div>
    </div>
  );
}

export default App;
